<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Bruger oprettelses rapport</div>
          <span class="subtitle-1 light-grey"
            >Oversigt over oprettelser pr. dag</span
          >
        </div>
        <v-spacer></v-spacer>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              outlined
              color="primary"
              @click="
                filtersTmp = cloneDeep(filters);
                filterDialog = true;
              "
            >
              <v-icon small>fas fa-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtrér</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="stats"
          :loading="loading"
          hide-default-footer
          :disable-pagination="false"
          :items-per-page="10000"
        >
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          ></v-progress-linear>

          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.date">
                <td>{{ item.date | utc_to_local_datetime("DD-MM-YYYY") }}</td>
                <td class="text-right">
                  {{ item.businesses | local_numbers }}
                </td>
                <td class="text-right">
                  {{ item.influencers | local_numbers }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="filterDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filtrér rapport
        </v-card-title>
        <v-card-text>
          <date-picker
            v-model="filtersTmp.start_date"
            label="Periode start"
            cancel-button
            reset-button
          ></date-picker>
          <date-picker
            v-model="filtersTmp.end_date"
            label="Periode slut"
            cancel-button
            reset-button
          ></date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              filters = cloneDeep(filtersTmp);
              filterDialog = false;
            "
          >
            Filtrér
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers.js";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime.js";
import DatePicker from "@/components/common/filters/DatePicker";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import { mapActions } from "vuex";

export default {
  components: { DatePicker },
  mixins: [search_filters_url],
  filters: { local_numbers, utc_to_local_datetime },
  data: () => ({
    headers: [
      {
        text: "Dato",
        align: "left",
        value: "date"
      },
      {
        text: "Virksomheder",
        align: "right",
        value: "businesses"
      },
      {
        text: "Influencere",
        align: "right",
        value: "influencers"
      }
    ],
    loading: true,
    stats: [],
    filterDialog: false,
    filtersTmp: {},
    filters: {}
  }),
  computed: {
    filtersCount() {
      return Object.keys(this.filters).length;
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.setUrlSearchFilters(this.filters);
        this.getStats();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("moderator", ["getSignUps"]),
    getStats() {
      this.loading = true;

      let filters = this.cloneDeep(this.filters);
      const params = {
        ...filters
      };

      this.getSignUps(params).then(response => {
        this.loading = false;
        this.stats = response;
      });
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();

    if (Object.keys(this.filters).length === 0) {
      this.filters.start_date = this.$moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD");
      this.filters.end_date = this.$moment().format("YYYY-MM-DD");
    } else {
      this.getStats();
    }
  }
};
</script>
